import React from 'react'
import { Link } from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'

const OurSolutions = () => {
    return (
        <section className="solutions-area pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="star" />
                        Our Solutions
                    </span>
                    <h2>What makes us different?</h2>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p> */}
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Transparency
                                </Link>
                            </h3>
                            <p>We provide a free consultation for your requirement and discuss about the cost up-front. All the progress will be communicated regularly. No hidden surprises.</p>

                            {/* <Link to="/service-details" className="view-details-btn">
                                View Details
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                    Latest Tech
                                </Link>
                            </h3>

                            <p>We use the latest tech stack for all our services. React for Web Development, AWS for cloud Services (Lambda, SES and more), Adobe products for Graphics and Editing.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                    Full Refund if you are not satisfied
                                </Link>
                            </h3>

                            <p>Contact our support team if you are not happy with our service and we will work with you. Refund will be provided if we can't make you satisfied.</p>


                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurSolutions;