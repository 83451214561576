import React from 'react'
import service1 from '../../assets/images/services/service1.png'
import service2 from '../../assets/images/services/service2.png'
import starIcon from '../../assets/images/star-icon.png'

const OurServices = () => {
    return (
        <React.Fragment>
            {/* Service Left Image Style */}
            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={service1} alt="service" />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="icon" /> Services
                                    </span>

                                    <h2>Design & Development</h2>
                                    <p>We take your project from the beginning to the end during the process. Maintainance support is provided. Our tech stack includes ReactJS, NodeJS, AWS, Docker and more...</p>
                                    <ul className="about-list mb-0">
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Responsive UI Design
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Software Development
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Hosting and Deployment in AWS
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Automation and Manual Test
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Fully Cloud Solution
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Bulk SMS and Emails
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            SEO Optimization
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Custom Domain and Email creation
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Left Image Style */}

            {/* Service Right Image Style */}
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">

                                <div className="content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="icon" /> Services
                                    </span>

                                    <h2>Graphics & Social Media Marketing</h2>
                                    <p>We provide the graphics as per customer needs which incluudes motion graphics, ads design, social media marketing design and more.</p>

                                    <ul className="our-mission-list mb-0">
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Motion Graphics for Social Media
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Facebook, Instagram promotion
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Video Editing
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Increase Social Media Follower
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Print Ready Design
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            And Many More
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img src={service2} alt="service" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Right Image Style */}
        </React.Fragment>
    )
}

export default OurServices;